<template>
  <div class="filter-selector-wrap">
    <label>Project Filter</label>
    <v-select
      class="custom-selector"
      v-model="selectedProject"
      :options="projects"
      :clearable="false"
      label="name"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: { vSelect },
  computed: {
    projects() {
      return this.getProjects()
    },
    selectedProject: {
      get() {
        return this.getSelectedProject()
      },
      async set(value) {
        this.setSelectedProject(value)
        this.$emit('value-change', value)
      }
    }
  },
  methods: {
    ...mapMutations(['setSelectedProject']),
    ...mapGetters(['getProjects', 'getSelectedProject'])
  }
}
</script>

<style lang="scss" scoped>
.filter-selector-wrap {
  min-width: 200px;
  .custom-selector .vs__dropdown-toggle {
    height: 42px;
    padding: 10px;
    align-items: center;
  }
}
</style>
