<template>
  <div class="projects-page">
    <tool-navigation />
    <div class="agGrid-page container">
      <div class="content-tool-header">
        <base-header>
          <div class="header-slot-items">
            <header-content-buttons
              :gridApi="gridApi"
              :delDisable="deleteButtonDisable"
              @add-rows="appendRow"
              @del-rows="deleteRows"
            />
          </div>
        </base-header>
        <div class="content-tool-filters">
          <project-selector
            @value-change="projectFilterApply"
            v-if="!isLoading"
          />
          <record-status-selector
            :statuses="selectors.recordStatus"
            :currentStatus="selectedRecordStatus"
            @value-change="recordStatusFilterApply"
            v-if="!isLoading"
          />
          <logs-view
            v-if="!isLoading"
            :logList="logList"
            :loadNeeded="needLoadLogs"
            @load-log-list="loadAllLogs"
          />
          <div class="header-loading" v-else>Loading</div>
        </div>
      </div>

      <div class="ag-grid-container">
        <div class="ag-table">
          <ag-grid-vue
            class="ag-theme-alpine"
            style="width: 100%; height: 100%"
            headerHeight="32"
            rowHeight="32"
            :gridOptions="gridOptions"
            :rowSelection="rowSelection"
            :enableRangeSelection="true"
            :enableFillHandle="true"
            :fillHandleDirection="'y'"
            :rowData="rowData"
            :columnDefs="columnDefs"
            :statusBar="statusBar"
            :getContextMenuItems="getContextMenuItems"
            :stopEditingWhenCellsLoseFocus="false"
            :suppressMovableColumns="true"
            @grid-ready="onGridReady"
            @filter-changed="stateEvents"
            @column-resized="stateEvents"
            @sort-changed="stateEvents"
            @column-visible="stateEvents"
            @column-pivot-changed="stateEvents"
            @column-row-group-changed="stateEvents"
            @column-value-changed="stateEvents"
            @column-moved="stateEvents"
            @column-pinned="stateEvents"
            @selectionChanged="onSelectionChange"
            @add-rows="appendRow"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Init components
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import { mapGetters, mapMutations } from 'vuex'
// Custom Cell Renderer
import ProfitRenderer from '@/agGridV2/renderers/profit.renderer.vue'

import ToolNavigation from '@/components/ToolNavigation.vue'
import AddRowStatusBar from './components/AddRowStatusBar.vue'
import HeaderContentButtons from './components/HeaderContentButtons.vue'
import LogsView from './components/LogsView/LogView.vue'
import ProjectSelector from './components/ProjectSelector.vue'
import RecordStatusSelector from './components/RecordStatusSelector.vue'
import DeadlineEditor from './editors/DeadlineEditor.vue'
import PackEditor from './editors/PackEditor.vue'
import RichTextEditor from './editors/RichTextEditor.vue'
import SelectorEditor from './editors/SelectorEditor.vue'
import LinkRenderer from './renderers/LinkRenderer.vue'
import SelectorColorRenderer from './renderers/SelectorColorRenderer.vue'
import SelectorRenderer from './renderers/SelectorRenderer.vue'
import WordsRenderer from './renderers/WordsRenderer.vue'
import { getColumnDefs } from './utils/getColumnDefs'
import { getContextMenuItems } from './utils/getContextMenuItems'

export default {
  components: {
    BaseHeader,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ProfitRenderer,
    ToolNavigation,
    // eslint-disable-next-line vue/no-unused-components
    AddRowStatusBar,
    HeaderContentButtons,
    ProjectSelector,
    // eslint-disable-next-line vue/no-unused-components
    DeadlineEditor,
    // eslint-disable-next-line vue/no-unused-components
    PackEditor,
    // eslint-disable-next-line vue/no-unused-components
    SelectorEditor,
    // eslint-disable-next-line vue/no-unused-components
    SelectorRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SelectorColorRenderer,
    // eslint-disable-next-line vue/no-unused-components
    WordsRenderer,
    // eslint-disable-next-line vue/no-unused-components
    LinkRenderer,
    RecordStatusSelector,
    // eslint-disable-next-line vue/no-unused-components
    RichTextEditor,
    LogsView
  },
  data() {
    return {
      isLoading: true,
      rowData: null,
      columnDefs: null,
      gridApi: null,
      rowSelection: 'multiple',
      blockedRows: new Map(),
      selectedRecordStatus: 'All',
      logList: [],
      needLoadLogs: true,
      gridOptions: {
        getRowId: (params) => {
          return params.data['_id']
        },
        onCellValueChanged: this.onCellValueChanged,
        onCellEditingStarted: this.cellEditingStarted,
        onCellEditingStopped: this.cellEditingStopped,
        processCellForClipboard: (params) => {
          let value
          if (!params.value) return
          if (typeof params.value === 'object') {
            value = JSON.stringify(params.value)
          } else value = params.value
          return value
        },
        defaultColDef: {
          enableCellChangeFlash: true
        },
        context: {
          componentParent: this
        },
        getRowStyle: (params) => {
          const id = params.data._id
          if (params.data.blocked) {
            const client = this.blockedRows.get(id)
            const name =
              client.name.split(' ')[0] +
              ' ' +
              client.name.split(' ')[1][0] +
              '.'
            return {
              '--userHighlightColor': client.color,
              '--userHighlightName': `'${name}'`,
              '--userHighlightNameColor': this.getContrastColor(client.color)
            }
          }
        },
        rowClassRules: {
          blocked: (params) => params.data.blocked,
          'ordered-status': (params) =>
            params.data.recordStatus === 'Content Is Ordered',
          'need-check-status': (params) =>
            params.data.recordStatus === 'Need to Check'
        }
      },
      records: null,
      projects: null,
      editors: null,
      selectors: null,
      statusBar: {
        statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent' }]
      },
      deleteButtonDisable: true,
      apiClient: null,
      socketActions: {
        lockCell: ({ cell, client }) => {
          this.blockedRows.set(cell.rowId, client)
          const node = this.gridApi.getRowNode(cell.rowId)
          node.data['blocked'] = true
          this.gridApi.redrawRows({
            rowNodes: [node]
          })
        },
        unlockCell: ({ cell }) => {
          this.blockedRows.set(cell.rowId)
          const node = this.gridApi.getRowNode(cell.rowId)
          node.data['blocked'] = false
          this.gridApi.redrawRows({
            rowNodes: [this.gridApi.getRowNode(cell.rowId)]
          })
        },
        modify: ({ action, data }) => {
          this.gridApi.applyTransaction({ [action]: data })
        }
      }
    }
  },
  computed: {
    selectedProject() {
      return this.getSelectedProject()
    }
  },
  methods: {
    async loadAllLogs() {
      console.log('start')
      this.logList = await this.apiClient.logs.get()
      console.log('end')

      this.needLoadLogs = false
    },
    getContrastColor(bgColor) {
      const color = bgColor.startsWith('#') ? bgColor.slice(1) : bgColor

      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)

      const yiq = (r * 299 + g * 587 + b * 114) / 1000

      return yiq > 150 ? '#000' : '#fff'
    },
    ...mapMutations(['setViewLoader', 'setSelectedProject']),
    ...mapGetters(['getSelectedProject', 'me', 'getApiClient']),
    onGridReady(params) {
      params.api.sizeColumnsToFit()
      this.gridApi = params.api
    },
    setState() {
      const state = this.$store.getters.getAgGridState(this.$route.name)
      if (state) {
        this.gridApi.applyColumnState({
          state: JSON.parse(state),
          applyOrder: true
        })
      }
      const stateFilter = this.$store.getters.getAgGridFilterChanged(
        this.$route.name
      )
      if (stateFilter) {
        this.gridApi.setFilterModel(JSON.parse(stateFilter))
      }
    },
    stateEvents(event) {
      this.gridApi.setColumnsVisible(
        ['project'],
        this.selectedProject._id === 'all'
      )
      if (event.type === 'filterChanged') {
        this.$store.dispatch('setAgGridFilterChanged', {
          page: this.$route.name,
          query: JSON.stringify(this.gridApi.getFilterModel())
        })
      } else {
        this.$store.dispatch('setAgGridState', {
          page: this.$route.name,
          query: JSON.stringify(this.gridApi.getColumnState())
        })
      }
    },
    copyRowData(data) {
      return data.map((datum) => {
        return {
          ...datum
        }
      })
    },
    getContextMenuItems(params) {
      return getContextMenuItems(params, this.apiClient, this.logList)
    },
    generateColumnDefs: ({ projects, editors, selectors }) => {
      const colDef = [...getColumnDefs({ projects, editors, selectors })]
      return colDef
    },
    async onCellValueChanged(event) {
      const { oldValue, newValue, colDef, node } = event
      if (oldValue === newValue) return

      const { field } = colDef
      const { id } = node
      if (!field || !id) return
      let data = { [field]: newValue }
      if (field === 'project') {
        data['projectManager'] = node.data.projectManager
      }
      try {
        const res = await this.apiClient.records.update(id, data)
        const { __v, ...resData } = res
        node.setData(resData)
        if (
          field === 'finalContent' &&
          (!resData.words || resData.words === 0)
        ) {
          const { __v, ...updatedData } =
            await this.apiClient.records.countWords(id, resData.finalContent)
          node.setData(updatedData)
        }
      } catch (err) {
        this.gridApi.refreshCells({
          rowNodes: [node],
          columns: [field]
        })
        console.error(err)
      }
    },
    async projectFilterApply() {
      const value = this.selectedProject
      const filterInstance = await this.gridApi.getColumnFilterInstance(
        'project'
      )
      if (!filterInstance) {
        return
      }
      if (value._id === 'all') {
        this.gridApi.setColumnsVisible(['project'], true)
        filterInstance.setModel(null)
      } else {
        this.gridApi.setColumnsVisible(['project'], false)
        filterInstance.setModel({
          filterType: 'set',
          values: [value._id]
        })
      }
      this.gridApi.onFilterChanged()
    },
    async recordStatusFilterApply(value) {
      if (value) {
        this.selectedRecordStatus = value
      }
      const filterInstance = await this.gridApi.getColumnFilterInstance(
        'recordStatus'
      )
      if (!filterInstance) {
        return
      }
      if (this.selectedRecordStatus === 'All') {
        this.gridApi.setColumnsVisible(['recordStatus'], true)
        filterInstance.setModel(null)
      } else {
        this.gridApi.setColumnsVisible(['recordStatus'], false)
        filterInstance.setModel({
          filterType: 'set',
          values: [this.selectedRecordStatus]
        })
      }
      this.gridApi.onFilterChanged()
    },
    async filterApply() {
      await this.projectFilterApply()
      await this.recordStatusFilterApply()
    },
    appendRow(rowCount) {
      if (this.selectedProject._id === 'all') {
        alert('Select a project before creating a row')
        return
      }
      const project = this.projects.find(
        (p) => p._id === this.selectedProject._id
      )
      if (!project) {
        alert('Error with project creation. Check console.')
        console.error(
          'Project not found! Project Selector Data: ',
          this.selectedProject
        )
        return
      }
      const createdArr = []
      for (let i = 0; i < rowCount; i++) {
        createdArr.push({
          project,
          projectManager: { _id: project.pm._id, name: project.pm.name }
        })
      }
      this.apiClient.records
        .create(createdArr)
        .then((data) => {
          this.gridApi.applyTransaction({ add: data })
          this.$nextTick(() => {
            this.filterApply()
          })
        })
        .catch((err) => console.error(err))
    },
    deleteRows(selectedRows) {
      let blockedSelect = false
      selectedRows.forEach((row) => {
        blockedSelect = blockedSelect || ('blocked' in row && row['blocked'])
      })
      if (blockedSelect) {
        alert('You select a blocked row')
        this.gridApi.deselectAll()
        return false
      }
      const isAllowed = confirm(
        `Are you sure you want to delete ${selectedRows.length} records?`
      )
      if (isAllowed) {
        const ids = []
        selectedRows.forEach((row) => ids.push(row['_id']))
        this.apiClient.records
          .removeMany(ids)
          .then((res) => {
            this.gridApi.applyTransaction({ remove: selectedRows })
            this.deleteButtonDisable = true
          })
          .catch((err) => alert(err))
      }
    },
    onSelectionChange() {
      const selectedRows = this.gridApi.getSelectedRows()
      this.deleteButtonDisable = selectedRows.length === 0
    },
    // socket logic
    cellEditingStarted(event) {
      this.apiClient.socket.lockCell(this.getCell(event))
    },
    cellEditingStopped(event) {
      this.apiClient.socket.unlockCell(this.getCell(event))
      if (event.colDef.field === 'words') {
        event.colDef.editable = false
      }
    },
    getCell(eventData) {
      const { colDef, node } = eventData
      const field = colDef.field
      const rowId = node.id
      return { colId: field, rowId }
    },
    socketHandler(event) {
      // parse message
      const message = JSON.parse(event.data)
      // get type
      const action = this.socketActions[message.type]
      // check type
      if (action) {
        action(message.data)
      } else {
        console.error('Socket action not found: ', message.type)
      }
    },
    async refreshWordsCell(recordId, finalContent) {
      const { __v, ...resData } = await this.apiClient.records.countWords(
        recordId,
        finalContent
      )
      const node = this.gridApi.getRowNode(recordId)
      node.setData(resData)
    }
  },
  async beforeMount() {
    this.$emitter.on('header_search_addon_changed', () => {
      this.gridApi.setGridOption(
        'quickFilterText',
        this.$store.getters.getSearchQuery(this.$route.name)
      )
    })
    this.$emitter.on('header_reset_state', () => {
      this.gridApi.resetColumnState()
      const timeout = setTimeout(() => {
        this.$store.dispatch('setAgGridState', {
          page: this.$route.name,
          query: ''
        })
        clearTimeout(timeout)
      }, 10)
    })
    this.$emitter.on('header_reset_filters', () => {
      if (this.gridApi && this.gridApi.destroyCalled === false) {
        this.gridApi.setFilterModel(null)
        this.gridApi.setGridOption('quickFilterText', null)
        this.setSelectedProject({ _id: 'all', name: 'All' })
        this.gridApi.setColumnsVisible(['project'], true)
        const timeout = setTimeout(() => {
          this.$store.dispatch('setAgGridFilterChanged', {
            page: this.$route.name,
            query: ''
          })
          clearTimeout(timeout)
        }, 10)
      }
    })
  },
  mounted() {
    ;(async () => {
      this.setViewLoader(true)
      // Authorize
      await this.$store.dispatch('authClient')
      this.apiClient = this.getApiClient()

      await this.$store.dispatch('loadProjects')
      // Getting data for table
      const [records, projects, editors, selectors] = await Promise.all([
        this.apiClient.records.get(),
        this.apiClient.projects.get(),
        this.apiClient.editors.get(),
        this.apiClient.selectors.get()
      ])
      this.projects = projects
      this.records = records
      this.editors = editors
      this.selectors = selectors
      this.columnDefs = Object.freeze(
        this.generateColumnDefs({ projects, selectors, editors })
      )
      if (records && records.length > 0) {
        this.rowData = Object.freeze(this.copyRowData(records))
      } else {
        this.rowData = []
      }
      // End getting data

      this.$nextTick(() => {
        this.gridApi.setGridOption(
          'quickFilterText',
          this.$store.getters.getSearchQuery(this.$route.name)
        )
        this.setState()
        this.filterApply()
        this.apiClient.socketConnect(this.socketHandler)

        this.setViewLoader(false)
        this.isLoading = false
      })
    })()
  }
}
</script>

<style lang="scss">
.custom-control-label {
  display: flex;
  align-items: center;
}
div:has(> .selector-color-renderer) {
  padding: 0;
  border: none;
}
.ag-cell[col-id='deadline'],
.ag-cell[col-id='pack'] {
  padding: 0;
  &.ag-cell-inline-editing {
    overflow: visible;
  }
}
.ag-cell:has(.custom-selector-wrap) {
  padding: 0;
  &.ag-cell-inline-editing {
    overflow: visible;
  }
}
.header-slot-items {
  width: 100%;
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  justify-content: space-between;
  align-items: center;
}
.content-buttons {
  margin-left: auto;
}
.ag-cell-value {
  line-height: 1.7em;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ag-row.blocked {
  overflow: visible;
  border-top: 2px solid var(--user-highlight-color);
  border-bottom: 2px solid var(--user-highlight-color);
  pointer-events: none;
  & .ag-cell {
    opacity: 0.5;
    &::before {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background-color: var(--user-highlight-color);
      z-index: -1;
      opacity: 0.5;
    }
  }
  &::after {
    content: var(--user-highlight-name);
    color: var(--user-highlight-name-color);
    background-color: var(--user-highlight-color);
    position: sticky;
    bottom: 100%;
    left: 0;
    font-size: 10px;
    line-height: 15px;
    padding: 2px;
    padding-top: 0;
  }
}
.ag-row.ordered-status {
  background-color: #d9d2e9;
}
.ag-row.need-check-status {
  background-color: #b7e1cd;
}
.content-tool-header {
  margin-bottom: 15px;
}
.content-tool-filters {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  flex-direction: row;
}
.rich-text-renderer {
  width: 100%;
  max-height: 150px;
  overflow: hidden;
  & > *:has(+ *) {
    margin-bottom: 10px;
  }
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    li {
      line-height: normal;
    }
  }
}
</style>
