<template>
  <div class="custom-selector-wrap">
    <v-select
      class="custom-selector"
      v-model="selectedValue"
      :options="values"
      :clearable="false"
      @input="stopEditing"
      @close="stopEditing"
      :label="label"
      :reduce="reduce"
      :appendToBody="true"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  name: 'selector-editor',
  components: { vSelect },
  data() {
    return {
      values: null,
      selectedValue: null,
      isEditing: false,
      api: null,
      input: null,
      template: '',
      label: null,
      reduce: null
    }
  },
  beforeMount() {
    this.values = this.params.values || []
    this.selectedValue = this.params.value || null
    this.api = this.params.api
    this.isEditing = this.params.cellStartedEdit
    this.template = this.params.colDef.headerName
    this.label = this.params.label
    this.reduce = this.params.reduce
  },
  mounted() {
    if (this.isEditing) {
      this.startEditing()
    }
    this.input = this.$el.querySelector('input')
  },
  methods: {
    getValue() {
      console.log(this.selectedValue)
      return this.selectedValue
    },
    startEditing() {
      this.$nextTick(() => {
        this.input.focus()
      })
    },
    stopEditing(value) {
      console.log(value)
      this.input.blur()
      this.api.stopEditing()
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-selector-wrap {
  width: 100%;
  height: 100%;
}
</style>
