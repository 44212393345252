<template>
  <div class="action-list" v-if="action === 'modify'">
    <ul v-for="entity in entities" :key="entity._id">
      <li>
        Changed:
        <span class="modify-field">{{ entity.column }}</span>
      </li>
      <li>
        Old Data:
        <span class="modify-field old-value">{{
          entity.oldValue || 'No Data'
        }}</span>
      </li>
      <li>
        New Data:
        <span class="modify-field new-value">{{
          entity.newValue || 'No Data'
        }}</span>
      </li>
    </ul>
  </div>
  <div class="action-list" v-else>
    <span class="capitalize">{{ action }}</span>
    {{ entities.length }} row(s) for
    {{ getProjectName(entities) }}
  </div>
</template>

<script>
export default {
  props: ['entities', 'action'],
  methods: {
    getProjectName(entity) {
      let value
      if (entity.oldValue) {
        value = entity.oldValue
      } else if (entity.newValue) {
        value = entity.newValue
      } else {
        return "Can't find project."
      }
      value = JSON.parse(value)
      return `${value.project.abbr} - ${value.project.type} project`
    }
  }
}
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.modify-field {
  padding: 3px;
  background-color: #7987ff;
  color: #fff;
  border-radius: 5px;
  &.old-value {
    background-color: #ed5e68;
  }
  &.new-value {
    background-color: #38b87d;
  }
}
.action-list {
  padding: 10px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
</style>
