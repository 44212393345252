<template>
  <div class="filter-selector-wrap">
    <label>Record Status Filter</label>
    <v-select
      class="custom-selector"
      v-model="selectedStatus"
      :options="options"
      :clearable="false"
      @input="handleInput"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  props: {
    statuses: Array,
    currentStatus: String
  },
  data() {
    return {
      selectedStatus: '',
      options: []
    }
  },
  mounted() {
    this.selectedStatus = this.currentStatus || 'All'
    this.options = ['All', ...this.statuses]
  },
  methods: {
    handleInput(value) {
      this.$emit('value-change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-selector-wrap {
  min-width: 200px;
  .custom-selector > .vs__dropdown-toggle {
    height: 42px;
    padding: 10px;
    align-items: center;
  }
}
</style>
