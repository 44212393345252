<template>
  <li class="log-item">
    <div class="log-item__header">
      <div>
        <div class="log-item__header-name">{{ log.user.name }}</div>
        <div class="log-item__header-time">
          {{ new Date(log.createdDate).toLocaleString() }}
        </div>
      </div>
      <div class="log-item__header-buttons">
        <button @click="print(log)">View</button>
        <button>Undo</button>
      </div>
    </div>
    <action-list :entities="log.affectedEntities" :action="log.action" />
  </li>
</template>

<script>
import ActionList from './ActionList.vue'
export default {
  components: { ActionList },
  props: { log: Object },
  methods: {
    print(msg) {
      console.log(msg)
    }
  }
}
</script>

<style lang="scss" scoped>
.log-item {
  background-color: #fff;
  border: 1px solid #7987ff;
  &__header {
    background-color: #7987ff;
    color: #fff;
    font-weight: 500;
    padding: 10px;
    border-bottom: 1px solid #7987ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-name {
      letter-spacing: 0.0125em;
      font-size: 18px;
      margin-bottom: 3px;
    }

    &-time {
      font-size: 14px;
    }

    &-buttons {
      display: flex;
      column-gap: 6px;
    }
  }
}
</style>
