<template>
  <div class="logs-wrapper">
    <button class="button-outline-blue" @click="openLogs">View Logs</button>
    <div class="logs-list-wrapper" :class="{ open: isOpen }">
      <div class="logs-list-title">
        <span>Edit History</span>
        <button @click="closeLogs">Close</button>
      </div>
      <div class="loader" v-if="logList.length === 0"></div>
      <log-list v-else :logList="logList" :isFirstOpen="isFirstOpen" />
    </div>
  </div>
</template>

<script>
import LogList from './components/LogList.vue'
export default {
  components: { LogList },
  data() {
    return {
      isFirstOpen: true,
      isOpen: false
    }
  },
  props: {
    logList: Array,
    loadNeeded: Boolean
  },
  methods: {
    highlightAffectedEntity(event) {
      console.log(event)
      this.$emit('highlight-log-entity')
    },
    openLogs() {
      if (this.loadNeeded) {
        this.$emit('load-log-list')
      }
      this.isOpen = true
    },
    closeLogs() {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: Roboto;
}
.logs-wrapper {
  margin-top: auto;
  margin-left: auto;
}
.logs-list-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 400px;
  background-color: #f4f5ff;
  padding: 15px;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s linear;
  transform: translateX(100%);
  overflow: auto;
  max-height: 100vh;
  &.open {
    transform: translateX(0);
  }
  .logs-list-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      content: '';
      height: 1px;
      background-color: #000;
      opacity: 0.4;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

// loader
.loader {
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 black;
  position: absolute;
  clip-path: inset(-40px 0 -5px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader:before {
  content: '';
  position: absolute;
  inset: auto calc(50% - 17px) 0;
  height: 50px;
  --g: no-repeat linear-gradient(#7987ff 0 0);
  background: var(--g), var(--g), var(--g), var(--g);
  background-size: 16px 14px;
  animation: l7-1 2s infinite linear, l7-2 2s infinite linear;
}
@keyframes l7-1 {
  0%,
  100% {
    background-position: 0 -50px, 100% -50px;
  }
  17.5% {
    background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px;
  }
  35% {
    background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px;
  }
  52.5% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px;
  }
  70%,
  98% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px),
      100% calc(100% - 16px);
  }
}
@keyframes l7-2 {
  0%,
  70% {
    transform: translate(0);
  }
  100% {
    transform: translate(200%);
  }
}
</style>
