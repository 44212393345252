<template>
  <ul class="log-list">
    <log-item v-for="log in logList" :key="log._id" :log="log" />
  </ul>
</template>

<script>
import LogItem from './LogItem.vue'
export default {
  components: { LogItem },
  props: {
    logList: Array,
    isFirstOpen: Boolean
  }
}
</script>

<style lang="scss" scoped>
.log-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
</style>
