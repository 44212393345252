<template>
  <div class="picker" :style="styleVariables">
    <date-picker
      v-model="value"
      format="MM/DD/YYYY"
      :placeholder="stringValue"
      @input="stopEditing"
      @close="stopEditing"
    />
    <span class="deadline-counter" v-if="isRenderCaution">
      {{ days === 0 ? '!' : Math.abs(this.days) }}
    </span>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      value: null,
      today: new Date(),
      api: null,
      isEditing: null
    }
  },
  computed: {
    styleVariables() {
      return {
        '--cautionColor': this.cautionColor,
        '--pointerEvents': this.isEditing ? 'auto' : 'none'
      }
    },
    cautionColor() {
      if (this.days <= 0) {
        return '#b13a41'
      } else if (this.days > 0 && this.days <= 3) {
        return '#cf940a'
      } else {
        return '#555'
      }
    },
    days() {
      if (!this.value) return
      const targetDate = new Date(this.value)
      this.today.setHours(0, 0, 0, 0)
      targetDate.setHours(0, 0, 0, 0)

      const diffInMillis = targetDate.getTime() - this.today.getTime()

      const diffInDays = diffInMillis / (1000 * 60 * 60 * 24)
      return diffInDays > 0 ? Math.ceil(diffInDays) : Math.floor(diffInDays)
    },
    stringValue() {
      if (this.value instanceof Date) {
        return this.value.toLocaleDateString('en-US')
      }
      return this.value || 'mm/dd/yyyy'
    },
    isRenderCaution() {
      const pmStatus = this.params.data.statusPM || ''
      return this.days <= 3 && pmStatus !== 'Published'
    }
  },
  beforeMount() {
    this.value = this.params.value ? new Date(this.params.value) : ''
    this.api = this.params.api
    this.isEditing = this.params.cellStartedEdit
  },
  mounted() {
    if (this.isEditing) {
      this.startEditing()
    }
  },
  methods: {
    getValue() {
      if (!this.value || this.value === 'mm/dd/yyyy') {
        return ''
      }
      return this.stringValue
    },
    startEditing() {
      this.$nextTick(() => {
        this.$el.querySelector('input').focus()
      })
    },
    stopEditing(value) {
      if (!value || value === 'mm/dd/yyyy') {
        this.value = ''
      }
      this.api.stopEditing()
    }
  }
}
</script>

<style lang="scss">
.picker {
  position: relative;
  width: 100%;
  cursor: pointer;
  pointer-events: var(--pointerEvents);
  .mx-input {
    border: 0;
    background: transparent;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .mx-datepicker {
    width: 100%;
  }

  .deadline-counter {
    position: absolute;
    font-size: 10px;
    line-height: 100%;
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    transition: background-color 0.25s ease;
    background-color: var(--cautionColor);
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
